import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../components/layout";
import { GetPaymentStatus } from "../Redux/actions/PaymentActions";

function PaymentStatus() {
  const dispatch = useDispatch();
  const paymentState = useSelector((state) => state.payment);
  useEffect(() => {
    dispatch(GetPaymentStatus());
  }, []);
  return (
    <Layout title="PaymentStatus">
      <p style={{ color: "black" }}>
        {paymentState.PaymentStatusLoading
          ? "Loading"
          : paymentState.PaymentStatusError
          ? paymentState.PaymentStatusError
          : paymentState.PaymentStatus}
      </p>
    </Layout>
  );
}

export default PaymentStatus;
